<template>
  <div class="d-flex justify-content-center">
    <nav class="" aria-label="Page navigation">
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <button class="page-link" aria-label="Previous" @click="previousPage">
            <inline-svg
              :src="require(`@/assets/icons/chevron-left.svg`)"
              height="16"
              width="16"
            >
            </inline-svg>
          </button>
        </li>
        <li
          v-for="pageNumber in totalPages"
          :key="pageNumber"
          class="page-item"
          :class="{ active: pageNumber === currentPage }"
        >
          <button class="page-link" @click="setCurrentPage(pageNumber)">
            {{ pageNumber }}
          </button>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <button class="page-link" aria-label="Next" @click="nextPage">
            <inline-svg
              :src="require(`@/assets/icons/chevron-right.svg`)"
              height="16"
              width="16"
            >
            </inline-svg>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "SeatsPagination",
  props: {
    currentPage: Number,
    totalPages: Number
  },
  methods: {
    setCurrentPage(pageNumber) {
      this.$emit("update", pageNumber);
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.setCurrentPage(this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.setCurrentPage(this.currentPage + 1);
      }
    }
  }
};
</script>
